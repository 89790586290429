import React, { useState } from "react";
import Modal from "./Modal";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

/**
 * Contact form (Get in touch)
 * Submits to Netlify Forms with JS so can display the confirmation (or error) message in proximity to the form rather than on a new page (i.e., the default Netlify Forms behavior).
 *  The fetch form submission code is courtesy of https://github.com/sw-yx/gatsby-netlify-form-example-v2/blob/master/src/pages/contact.js and is largely unchanged.
 * This might be a better way to do it (sans the jQuery): https://docs.netlify.com/forms/setup/#submit-forms-via-ajax.
 * *** UPDATE *** form has been switched and no longer uses Netlify. Instead uses EmailJS to handle submissions directly to Nick's email
 */
export default function Contact() {
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    message: ""
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  // const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
    // console.log(state);
  };

  const handleFocus = e => {
    setIsError(false);
    setIsSuccess(false);
  };

  // Submits to Netlify Forms with fetch
  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    // This templateId is created in EmailJS.com
    const templateId = 'template_r28pftj';

    console.log("form submitted");

    // This is a custom method from EmailJS that takes the information 
    // from the form and sends the email with the information gathered 
    // and formats the email based on the templateID provided.
    sendToEmailJS(templateId, {
      firstname: state.firstname,
      lastname: state.lastname,
      email: state.email,
      message: state.message,
    })

    // fetch("/", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //   body: encode({
    //     "form-name": form.getAttribute("name"),
    //     ...state,
    //   }),
    // })
    //   .then(() => {
    //     console.log("form submitted successfully");
    //     setIsSuccess(true);
    //   })
    //   .catch(error => {
    //     console.error("Form submission error: ", error);
    //     setIsError(true);
    //   });
  };

  const sendToEmailJS = (templateId, variables) => {
    window.emailjs.send(
      'service_06taxmb', templateId,
      variables
    ).then(res => {
      // Email successfully sent
      console.log("form submitted successfully");
      setIsSuccess(true);
    })
      // Email Failed to send Error alert
      .catch(err => {
        console.error("Form submission error: ", err);
        setIsError(true);
      })
  }

  const handleCloseModal = () => setIsSuccess(false);

  const successMessage = "I can't wait to connect with you. I normally respond within 1-2 days, but if you would like to get in touch quicker, please do not hesitate to give me a call on (310) 923-5258. Thank you and talk soon!";

  return (
    <div id="view-1-card-8" className="card card--contact bgc-01">
      <div className="inner">
        <h2 className="hdg-contact">I LOVE to TALK, so let’s TALK.</h2>

        <form
          name="get-in-touch"
          method="post"
          data-netlify="true"
          netlify-honeypot="just-for-you"
          className="contact"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="get-in-touch" />
          <p className="not-human">
            <label>
              Not human:{" "}
              <input
                name="just-for-you"
                onChange={handleChange}
                onFocus={handleFocus}
              />
            </label>
          </p>

          <label htmlFor="firstname">
            First Name*
            <input
              type="text"
              id="firstname"
              name="firstname"
              required
              className="contact__field"
              onChange={handleChange}
              onFocus={handleFocus}
            />
          </label>

          <label htmlFor="lastname">
            Last Name*
            <input
              type="text"
              id="lastname"
              name="lastname"
              required
              className="contact__field"
              onChange={handleChange}
              onFocus={handleFocus}
            />
          </label>

          <label htmlFor="email">
            Email*
            <input
              type="email"
              id="email"
              name="email"
              required
              className="contact__field"
              onChange={handleChange}
              onFocus={handleFocus}
            />
          </label>

          <label htmlFor="message">
            Message*
            <textarea
              id="message"
              name="message"
              required
              className="contact__field"
              onChange={handleChange}
              onFocus={handleFocus}
            ></textarea>
          </label>

          <input
            type="hidden"
            name="subject"
            value="Contact form submitted on your site"
          />

          <input type="submit" value="Submit" className="btn-submit" />
        </form>

        <Modal
          content={successMessage}
          modalStyle="solid"
          isModalOpen={isSuccess && !isError ? true : false}
          handleCloseModal={handleCloseModal}
          confirmation
        />

        {/* {isSuccess && !isError && (
          <p style={{ color: "green", fontWeight: "bold" }}>
            Thank you! Your email has been sent.
          </p>
        )} */}

        {isError && !isSuccess && (
          <p style={{ color: "red", fontWeight: "bold" }}>
            Sorry, there was an error when submitting your email. Please try
            again.
          </p>
        )}
      </div>
    </div>
  );
}
