import React from "react";
import { DialogOverlay, DialogContent } from "@reach/dialog";

/**
 * Display video in Reach UI Dialog modal component
 * @param {string} content Content ID of YouTube video
 * @param {string} modalStyle Should be "solid" or "semi-transparent" to dictate visual style
 * @param {Boolean} isModalOpen A true value means modal displays
 * @param {function} handleCloseModal Parent component function that closes modal
 */

export default function ModalMedia({
  content,
  modalStyle,
  isModalOpen,
  handleCloseModal,
}) {
  const handleClose = () => handleCloseModal();
  return (
    <DialogOverlay
      isOpen={isModalOpen}
      onDismiss={handleClose}
      className={`modal modal--${modalStyle}`}
    >
      <DialogContent
        aria-label={`Video of one of my top 5 moments.`}
        className={`modal__content--${modalStyle}`}
      >
        <div className="inner modal__inner-header">
          <button type="button" className="btn-close" onClick={handleClose}>
            <span className="visuallyHidden">Close</span>
            <span aria-hidden>
              <svg className="icon icon--close-modal-x">
                <use xlinkHref="#icon-close-modal-x" />
              </svg>
            </span>
          </button>
        </div>

        <div className="modal__inner">
          {content && (
            <div className="modal__video-wrapper">
              <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${content}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media;"
                allowFullScreen
                title="Nick Ferrara video"
              ></iframe>
            </div>
          )}
        </div>
      </DialogContent>
    </DialogOverlay>
  );
}
