import React from "react";
import Arrows from "./Arrows";

// import placeholder from "../img/events/event-placeholder-image.png";

// An event listing within the Future view
const FutureEvent = ({ cardId, viewId, title, location, date, content, hasArrows }) => {
  return (
    <div id={`view-${viewId}-card-${cardId}`} className="card card--event">
      <div className="inner inner--event">
        <div className="inner__date">
          <p>
            {date[0]} <br />
            <span>{date[1]}</span>
            <br /> {date[2]}
          </p>
        </div>
        <div className="inner__container">
          <h3>{title}</h3>
          <p className="location">{location}</p>
          <div className="text-image-container">
            <p className="description">
              {content}
            </p>
            {/* <img src={placeholder} alt="event" className="rounded-10" /> */}
          </div>

          {/* <a href="https://www.stellaragency.com" className="btn-attend">
            Attend
          </a> */}
        </div>
      </div>

      {hasArrows && (
        <Arrows
          viewId={viewId}
          toCardId={`${parseInt(cardId, 10) + 1}`}
          color="dark"
          size="med"
        />
      )}
    </div>
  );
};

/**
 * View: Future
 */
export default function ViewFuture() {
  return (
    <section id="view-2" className="view view-2">
      <div className="card card--intro">
        <div className="inner inner--intro-view-2">
          <h2 className="hdg-main hdg-main--mod">
            <span>Check out my</span>{" "}
            <span className="hdg-main__major hdg-main__major--mod">
              Event <br />
              Schedule
            </span>
          </h2>
        </div>

        <Arrows viewId="2" toCardId="1" color="light" size="lg" />
      </div>

      <FutureEvent
        cardId="35"
        viewId="2"
        title="Herbalife 24 Triathlon"
        location="Los Angeles, CA"
        date={["Oct", "25", "2021"]}
        content="Glad to be back to host this unique race. The best of the best will start in Venice Beach and cross the finish line at L.A. Live."
        hasArrows={true}
      />
      <FutureEvent
        cardId="34"
        viewId="2"
        title="Nautica Malibu Triathlon"
        location="Malibu, CA"
        date={["Sep", "25-26", "2021"]}
        content="I'll be chatting up the celebrities and the thousands of other triathletes that take to the shores of Zuma Beach to benefit Children's Hospital Los Angeles."
        hasArrows={true}
      />
      <FutureEvent
        cardId="33"
        viewId="2"
        title="American Express Unstaged Meet and Greet with SZA"
        location="Louisville, KY"
        date={["Jun", "17", "2021"]}
        content="Hosting an intimate opportunity for select fans to chat with Grammy nominated and Multi Platinum artist SZA"
        hasArrows={true}
      />
      <FutureEvent
        cardId="32"
        viewId="2"
        title="Kentucky Derby"
        location="Louisville, KY"
        date={["May", "1", "2021"]}
        content="The most exciting 2 minutes in sports that is 'The Run for the Roses' is back and so am I!"
        hasArrows={true}
      />
      <FutureEvent
        cardId="31"
        viewId="2"
        title="Kentucky Derby Eve Party"
        location="Louisville, KY"
        date={["Apr", "30", "2021"]}
        content="I'll be emceeing the Derby eve party that welcomes back the Derby to life!"
        hasArrows={true}
      />
      <FutureEvent
        cardId="30"
        viewId="2"
        title="American Express Unstaged with Maroon 5"
        location="Virtual"
        date={["Mar", "29", "2021"]}
        content="I'll be hosting and moderating An exclusive opportunity for 10 lucky fans to engage with Adam Levine and Jesse Carmichael."
        hasArrows={true}
      />
      <FutureEvent
        cardId="29"
        viewId="2"
        title="Unstaged Meet and Greet with Sam Smith"
        location="Virtual"
        date={["Oct", "29", "2020"]}
        content="Hosting an intimate opportunity for selected fans to virtually meet Sam Smith in the eve of his new album Love Goes."
        hasArrows={true}
      />
      <FutureEvent
        cardId="28"
        viewId="2"
        title="Nautica Malibu Triathlon"
        location="Virtual"
        date={["Sep", "26", "2020"]}
        content="Virtually hosting the many celebrities that are still competing for the cause that is Children's Hospital Los Angeles."
        hasArrows={true}
      />
      <FutureEvent
        cardId="27"
        viewId="2"
        title="US Open Womens and Mens Final"
        location="Virtual"
        date={["Sep", "12-13", "2020"]}
        content="Virtual Host of the the US Open Tennis Womens and Mens Final from Arthur Ashe Stadium in New York."
        hasArrows={true}
      />
      <FutureEvent
        cardId="26"
        viewId="2"
        title="NBA Playoffs"
        location="Virtual"
        date={["Sep", "1-30", "2020"]}
        content="The virtual fan host of the L.A. Clippers playoffs run from the bubble in Orlando! GO CLIPS GO!!"
        hasArrows={true}
      />
      <FutureEvent
        cardId="25"
        viewId="2"
        title="Super Bowl"
        location="Miami, FL"
        date={["Feb", "1-12", "2020"]}
        content="A new champion will be crowned in South Florida and Ill be on the mic at several festivities surrounding the big game!"
        hasArrows={true}
      />
      <FutureEvent
        cardId="24"
        viewId="2"
        title="2020 BCS National Championship"
        location="New Orleans, LA"
        date={["Jan", "10-14", "2020"]}
        content="Back at it again for AT&T as ill be hosting the Fan Fest."
        hasArrows={true}
      />
      <FutureEvent
        cardId="23"
        viewId="2"
        title="2020 CES"
        location="Las Vegas, Nevada"
        date={["Jan", "6-13", "2020"]}
        content="I'll be presenting the latest in athletic technology for a surprise client at the worlds largest convention of new products and technology."
        hasArrows={true}
      />
      <FutureEvent
        cardId="22"
        viewId="2"
        title="In N Out Burger Christmas Party Host"
        location="Ontario, CA"
        date={["Dec", "20", "2019"]}
        content="Tis the season! 'That's What a Hamburger's All About'"
        hasArrows={true}
      />
      <FutureEvent
        cardId="21"
        viewId="2"
        title="Loyola Marymount Basketball Homecoming Game"
        location="Los Angeles, CA"
        date={["Dec", "16", "2019"]}
        content="I'll be on the mic with the Lion Faithful in the 'House that Hank Built'!"
        hasArrows={true}
      />
      <FutureEvent
        cardId="20"
        viewId="2"
        title="Formula 1 Hollywood Live"
        location="Hollywood, CA"
        date={["Oct", "30", "2019"]}
        content="I'll be live and streaming with my co host David Coulthard as we witness the finest F1 drivers do donuts and display driving talents on Hollywood Blvd."
        hasArrows={true}
      />
      <FutureEvent
        cardId="19"
        viewId="2"
        title="NBA Opening Night on TNT"
        location="Los Angeles, CA"
        date={["Oct", "16", "2019"]}
        content="NBA is back and I'll be on the mic alongside Ernie, Shaq, Charles and Kenny as we start the season from Staples Center."
        hasArrows={true}
      />
      <FutureEvent
        cardId="18"
        viewId="2"
        title="Scott's MLB Scott's Big League Experience"
        location=""
        date={["Sep", "26", "2019"]}
        content="Honored to host the unveiling of a new field to hundreds of little leaguers. Play Ball!"
        hasArrows={true}
      />
      <FutureEvent
        cardId="17"
        viewId="2"
        title="Nautica Malibu Triathlon"
        location="Malibu, CA"
        date={["Sep", "12-13", "2019"]}
        content="I'll be talking TRI from the shores of Zuma Beach with celebrities as we benefit Children's Hospital Los Angeles."
        hasArrows={true}
      />
      <FutureEvent
        cardId="16"
        viewId="2"
        title="MLS Rivalry Week Presented by Heineken"
        location="Cincinnati, OH"
        date={["Aug", "20", "2019"]}
        content="Cincinnati FC vs Columus Crew. I'll be rocking the party for Heineken in this Buckeye battle!"
        hasArrows={true}
      />
      <FutureEvent
        cardId="15"
        viewId="2"
        title="US Women's Soccer National Team Victory Tour"
        location="Pasadena, CA"
        date={["Aug", "3", "2019"]}
        content="Help me welcome the World Cup Champions at the Rose Bowl!"
        hasArrows={true}
      />
      <FutureEvent
        cardId="14"
        viewId="2"
        title="MLS All Star Game"
        location="Orlando, FL"
        date={["Jul", "31", "2019"]}
        content="The best of the MLS will be on hand as I handle the hosting at the MLS Fan Fest."
        hasArrows={true}
      />
      <FutureEvent
        cardId="13"
        viewId="2"
        title="Wells Fargo Summer Concert Series"
        location="New York, NY"
        date={["Jun", "23", "2019"]}
        content="Under the vessel in the heart of hudson yards I'll be introducing some of the biggest acts in music."
        hasArrows={true}
      />
      <FutureEvent
        cardId="12"
        viewId="2"
        title="Day at The Beach Triathlon"
        location="Hermosa Beach, CA"
        date={["Jun", "23", "2019"]}
        content="From the shore to the finish I'll be motivating and getting motivated!"
        hasArrows={true}
      />
      <FutureEvent
        cardId="11"
        viewId="2"
        title="NCAA College World Series"
        location="Omaha, NE"
        date={["Jun", "13-15", "2019"]}
        content="I'll be chattin with baseballs elite including Wade Boggs, Tom Glavine and David Eckstein at the AT&T Fan Fest."
        hasArrows={true}
      />
      <FutureEvent
        cardId="10"
        viewId="2"
        title="Herbalife 24 L.A. Triathlon"
        location="Los Angeles, CA"
        date={["Jun", "3", "2019"]}
        content="I'll be on the mic start to finish as thousands of atletes SWIM BIKE AND RUN from Venice to beach to the finish at L.A. Live."
        hasArrows={true}
      />
      <FutureEvent
        cardId="9"
        viewId="2"
        title="US Women's Soccer vs Belgium"
        location="Louisville, KY"
        date={["May", "26", "2019"]}
        content="I'll be on the mic at the She Believes Cup series for Johnson & Johnson. USA, USA!!!"
        hasArrows={true}
      />
      <FutureEvent
        cardId="8"
        viewId="2"
        title="Kentucky Derby"
        location="Louisville, KY"
        date={["May", "4-5", "2019"]}
        content="I'll be on the worlds largest screen at Churchill Downs Lets 'Run for the Roses'!"
        hasArrows={true}
      />
      <FutureEvent
        cardId="7"
        viewId="2"
        title="NFL Draft"
        location="Nashville, TN"
        date={["Apr", "21-23", "2019"]}
        content="The lives of many young men will forever change and I'll be on the mic helping set the stage!"
        hasArrows={true}
      />
      <FutureEvent
        cardId="6"
        viewId="2"
        title="NCAA Final Four AT&T"
        location="Atlanta, GA"
        date={["Apr", "5-8", "2019"]}
        content="Come join the Madness of March. I'll be holding it down and talking with the legends at the AT&T Final 4 Fan Experience"
        hasArrows={true}
      />
      <FutureEvent
        cardId="5"
        viewId="2"
        title="BNP Paribas Open"
        location="Tucson, AZ"
        date={["Mar", "11-17", "2019"]}
        content="Time for tennis! I'll be moving the crowds in 'Tennis paradise' Come witness the best of the best battle in the desert!"
        hasArrows={true}
      />
      <FutureEvent
        cardId="4"
        viewId="2"
        title="NBA All Star Game"
        location="Charlotte, NC"
        date={["Feb", "16-17", "2019"]}
        content="I'll be hosting the NBA House and talking hoops with basketball legends past, present and future!"
        hasArrows={true}
      />
      <FutureEvent
        cardId="3"
        viewId="2"
        title="Los Angeles Clippers Game"
        location="Los Angeles, CA"
        date={["Feb", "13", "2019"]}
        content="Join me and CLIPPER nation as Ill be rocking the the crowd at Staples Center GO CLIPS GO!"
        hasArrows={true}
      />
      <FutureEvent
        cardId="2"
        viewId="2"
        title="Superbowl NFL"
        location="Atlanta, GA"
        date={["Jan/Feb", "25-3", "2019"]}
        content="I'll be on the mic presiding over the action as we celebrate the shield at the Super Bowl Experience."
        hasArrows={true}
      />
      <FutureEvent
        cardId="1"
        viewId="2"
        title="CES Presenter Samsung"
        location="Las Vegas, NV"
        date={["Jan", "7-10", "2019"]}
        content="I'll be presenting the latest smart home technology for Samsung at the worlds largest convention of new products and technology."
        hasArrows={false}
      />
    </section>
  );
}
