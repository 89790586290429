// import React, { useState } from "react";
// import ModalMedia from "./ModalMedia";

// // Interviews imgs
// import interview01 from "../img/interviews/01.png";
// import interview02 from "../img/interviews/02.png";
// import interview03 from "../img/interviews/03.png";
// import interview04 from "../img/interviews/04.png";

// /**
//  * List of interviews, and buttons that display the interviews in modal.
//  */
// export default function CardInterviewsList() {
//   // Content for list (in order in which they display)
//   const interviews = [
//     {
//       img: interview01,
//       who: "James Patterson",
//       videoId: "2WHDlK8G2fg",
//     },
//     {
//       img: interview02,
//       who: "Oguchi Onyewu",
//       videoId: "Wjste2_tLrc",
//     },
//     {
//       img: interview03,
//       who: "Jonathan Motley",
//       videoId: "Wjste2_tLrc",
//     },
//     {
//       img: interview04,
//       who: "Zac Efron",
//       videoId: "2WHDlK8G2fg",
//     },
//   ];

//   // Modal state
//   const [showDialog, setShowDialog] = useState(false);
//   const handleCloseModal = () => setShowDialog(false);
//   const [contentId, setContentId] = useState();

//   // Display content in modal for the button clicked/tapped
//   function handleClickShowInModal(id) {
//     setContentId(id);
//     setShowDialog(true);
//   }

//   return (
//     <>
//       <ul className="interviews">
//         {interviews.map((interview, index) => {
//           return (
//             <li key={index} className="interview">
//               <button
//                 type="button"
//                 className="interview__btn"
//                 onClick={() => handleClickShowInModal(interview.videoId)}
//               >
//                 <img src={interview.img} alt="" />
//                 <span className="visuallyHidden">
//                   {`play video of my interview with ${interview.who}`}
//                 </span>
//                 <svg className="icon icon--play-arrow">
//                   <use xlinkHref="#icon-play-arrow" />
//                 </svg>
//               </button>
//               {`${interview.who}`}
//             </li>
//           );
//         })}
//       </ul>

//       <ModalMedia
//         content={contentId}
//         modalStyle="semi-transparent"
//         isModalOpen={showDialog}
//         handleCloseModal={handleCloseModal}
//       />
//     </>
//   );
// }


import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Modal from "./Modal";

// Interviews imgs
import interview01 from "../img/interviews/01.png";
import interview02 from "../img/interviews/06.png";
import interview03 from "../img/interviews/07.png";
import interview04 from "../img/interviews/05.png";

/**
 * List of interviews, and buttons that display the interviews in modal.
 */
export default function CardInterviewsList() {
  const interviews = [
    {
      who: "James Patterson",
      img: interview01
    },
    {
      who: "Bob Iger",
      img: interview02
    },
    {
      who: "Laila Ali",
      img: interview03
    },
    {
      who: "Dwyane Wade",
      img: interview04
    }
  ]

  // Content for modals
  const data = useStaticQuery(graphql`
    query ModalQuery {
      allMarkdownRemark(
        sort: { fields: frontmatter___slot, order: ASC }
        filter: { frontmatter: { type: { eq: "modal" } } }
      ) {
        nodes {
          frontmatter {
            title
            type
            slot
          }
          html
        }
      }
    }
  `);

  const modalContent = data.allMarkdownRemark.nodes;

  // Modal state
  const [showDialog, setShowDialog] = useState(false);
  const handleCloseModal = () => setShowDialog(false);
  const [contentId, setContentId] = useState(-1);

  // Display content in modal for the button clicked/tapped
  function handleClickShowInModal(id) {
    setContentId(id);
    setShowDialog(true);
  }

  return (
    <>
      <ul className="interviews">
        {interviews.map((interview, index) => {
          return (
            <li key={index} className="interview">
              <button
                type="button"
                className="interview__btn"
                onClick={() => handleClickShowInModal(index)}
              >
                <img src={interview.img} alt="" />
                <span className="visuallyHidden">
                  view more in modal
                </span>
                {
                  index !== 2 ?
                    <svg className="icon icon--play-arrow">
                      <use xlinkHref="#icon-play-arrow" />
                    </svg>
                    :
                    null
                }
              </button>
              {`${interview.who}`}
            </li>
          );
        })}
      </ul>

      <Modal
        content={contentId > -1 ? modalContent[contentId] : ""}
        modalStyle="solid"
        isModalOpen={showDialog}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
}
