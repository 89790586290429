import React from "react";

// From https://www.npmjs.com/package/scroll-into-view
// var scrollIntoView = require("scroll-into-view");
// Problem: behaves inconsistently and oddly on iPhone 5S

/**
 * Down arrows button in cards.
 * @param {string} viewId Number of view it appears in (0, 1, or 2)
 * @param {string} toCardId Number from card id (0, 1, 2, 3, etc.) that the arrow btn should anchor to
 * @param {string} color Must be dark, light, or purp.
 * @param {string} size (optional) Defaults to small. Pass in med or lg as desired.
 */
export default function Arrows({ viewId, toCardId, color, size }) {
  function handleClick(e) {
    e.preventDefault();

    // Uses the native scrollIntoView method, aided by the smoothscroll-polyfill polyfill loaded in index.js. Polyfill is necessary for Safari and iOS Safari, at the least. They support scrollIntoView but not the behavior option for it. So without the polyfill, Safari would just jump to the anchor.
    document
      .getElementById(`view-${viewId}-card-${toCardId}`)
      .scrollIntoView({ behavior: "smooth" });

    // Cross-browser example with scroll-into-view pkg (has behavior issues on iPhone 5S)
    // const scrollTo = document.getElementById(`view-${viewId}-card-${toCardId}`);
    // scrollIntoView(scrollTo, {
    //   time: 500,
    // });
  }

  return (
    <a
      href={`#view-${viewId}-card-${toCardId}`}
      className="btn-arrow"
      onClick={e => handleClick(e)}
    >
      <svg
        className={`icon icon--arrows icon--arrows-${color} ${
          size ? `icon--arrows-${size}` : ""
        }`}
      >
        <use xlinkHref="#icon-arrows" />
      </svg>
    </a>
  );
}
