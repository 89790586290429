import React, { useRef, useState } from "react";
import { graphql } from "gatsby";
// import Header from "../components/Header";
import Carousel from "../components/Carousel";
import Icons from "../components/Icons";
import "../styles/site.scss";
import smoothscroll from "smoothscroll-polyfill";

/**
 * Home
 * @param {object} data GraphQL data for hero images used in Present view
 */
export default function Home({ data }) {
  // kick off the polyfill!
  if (typeof window !== "undefined") {
    smoothscroll.polyfill();
  }

  // Switch view when user clicks/taps a nav button
  const [slideIndex, setSlideIndex] = useState(1);
  const headerRef = useRef(null);

  function handleClickNavLink(index, e) {
    if (e) {
      e.preventDefault();
    }
    setSlideIndex(index);
    setNavIsOpen(false);

    // Only run this in the browser since Node doesn't have window object
    if (typeof window !== "undefined") {
      if (window.scrollY && window.scrollY > 0) {
        window.scroll(0, 0);
      }
    }
  }

  // Open and close mobile nav
  const [navIsOpen, setNavIsOpen] = useState(false);

  function handleToggleNav() {
    setNavIsOpen(!navIsOpen);
  }

  return (
    <>
      <header
        id="header"
        ref={headerRef}
        className={`header header--${slideIndex}-active`}
      >
        <button
          type="button"
          className="btn-toggle-nav"
          onClick={handleToggleNav}
        >
          <span className="visuallyHidden">
            toggle navigation open and closed
          </span>

          {navIsOpen ? `X` : `=`}
        </button>

        <p className="header__name">
          <a href="#view-1" onClick={e => handleClickNavLink(1, e)}>
            Nick Ferrara
          </a>
        </p>

        <nav
          className={`navWrapper ${
            navIsOpen ? ` navWrapper--open` : ` navWrapper--is-closed`
            }`}
        >
          <ul className="nav">
            <li>
              <a
                href="#view-0"
                onClick={e => handleClickNavLink(0, e)}
                className={slideIndex === 0 ? `nav--0-active` : undefined}
              >
                Top 5 Moments
              </a>
            </li>
            <li>
              <a
                href="#view-1"
                onClick={e => handleClickNavLink(1, e)}
                className={slideIndex === 1 ? `nav--1-active` : undefined}
              >
                My Story
              </a>
            </li>
            <li>
              <a
                href="#view-2"
                onClick={e => handleClickNavLink(2, e)}
                className={slideIndex === 2 ? `nav--2-active` : undefined}
              >
                My Calendar
              </a>
            </li>
          </ul>
        </nav>
      </header>
      <Carousel
        slideIndex={slideIndex}
        handleSlideSwipeChange={handleClickNavLink}
        heroBgWhat={data.heroBgWhat}
        heroBgWhatMobile={data.heroBgWhatMobile}
        heroBgHow={data.heroBgHow}
        heroBgHowMobile={data.heroBgHowMobile}
        heroBgInterviews={data.heroBgInterviews}
        heroBgInterviewsMobile={data.heroBgInterviewsMobile}
        heroBgIntro={data.heroBgIntro}
        heroBgIntroMobile={data.heroBgIntroMobile}
      />
      <Icons />
    </>
  );
}

export const query = graphql`
  query {
    heroBgIntro: file(relativePath: { eq: "hero/kentucky-derby.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    heroBgIntroMobile: file(relativePath: { eq: "hero/kentucky-derby-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    heroBgWhat: file(relativePath: { eq: "hero/bg-what-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    heroBgWhatMobile: file(relativePath: { eq: "hero/bg-what-2-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    heroBgHow: file(relativePath: { eq: "hero/bg-how.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    heroBgHowMobile: file(relativePath: { eq: "hero/bg-how-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    heroBgInterviews: file(relativePath: { eq: "hero/bg-interviews-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    heroBgInterviewsMobile: file(relativePath: { eq: "hero/bg-interviews-2-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

// Add this to query if want to use an image for the intro hero background instead of the video:
// heroBgIntro: file(relativePath: { eq: "hero/bg-intro.jpg" }) {
//   childImageSharp {
//     fluid(maxWidth: 1440, quality: 90) {
//       ...GatsbyImageSharpFluid
//     }
//   }
// }
// Then pass this to Carousel:
//   heroBgIntro={data.heroBgIntro}
