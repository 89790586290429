// import React, { useState } from "react";
// import { useStaticQuery, graphql } from "gatsby";
// import Modal from "./Modal";

// /**
//  * List of companies worked with, and buttons that display the content in modal.
//  */
// export default function CardWorkedList() {
//   // Content for list (in order in which they display)
//   const workedWith = ["NBA", "Samsung", "The Kentucky Derby", "Pepsi", "NFL"];

//   // Content for modals
//   const data = useStaticQuery(graphql`
//     query ModalQuery {
//       allMarkdownRemark(
//         sort: { fields: frontmatter___slot, order: ASC }
//         filter: { frontmatter: { type: { eq: "modal" } } }
//       ) {
//         nodes {
//           frontmatter {
//             title
//             type
//             slot
//           }
//           html
//         }
//       }
//     }
//   `);

//   const modalContent = data.allMarkdownRemark.nodes;

//   // Modal state
//   const [showDialog, setShowDialog] = useState(false);
//   const handleCloseModal = () => setShowDialog(false);
//   const [contentId, setContentId] = useState(-1);

//   // Display content in modal for the button clicked/tapped
//   function handleClickShowInModal(id) {
//     setContentId(id);
//     setShowDialog(true);
//   }

//   return (
//     <>
//       <ul className="worked">
//         {workedWith.map((who, index) => {
//           return (
//             <li key={index} className="worked__item">
//               <button
//                 type="button"
//                 className="worked__btn"
//                 onClick={() => handleClickShowInModal(index)}
//               >
//                 <span className="worked__who">{who}</span>
//                 <span className="visuallyHidden">view more in modal</span>
//               </button>
//             </li>
//           );
//         })}
//       </ul>

//       <Modal
//         content={contentId > -1 ? modalContent[contentId] : ""}
//         modalStyle="solid"
//         isModalOpen={showDialog}
//         handleCloseModal={handleCloseModal}
//       />
//     </>
//   );
// }


import React, { useState } from "react";
import ModalMedia from "./ModalMedia";

/**
 * List of companies worked with, and buttons that display the content in modal.
 */
export default function CardWorkedList() {
  const workedWith = [
    {
      who: "NBA",
      videoId: "pqQgTfDmudU",
    },
    {
      who: "Samsung",
      videoId: "bNZ3IQ3VNgU",
    },
    {
      who: "The Kentucky Derby",
      videoId: "s5SO2cKV1FI",
    },
    // {
    //   who: "Pepsi",
    //   videoId: "NNRQE6E0DwI",
    // },
    {
      who: "NFL",
      videoId: "TOsIMsvBzko"
    },
    {
      who: "AT&T",
      videoId: "OG_8hNtMaN0"
    }
  ];

  // Modal state
  const [showDialog, setShowDialog] = useState(false);
  const handleCloseModal = () => setShowDialog(false);
  const [contentId, setContentId] = useState();

  // Display content in modal for the button clicked/tapped
  function handleClickShowInModal(id) {
    setContentId(id);
    setShowDialog(true);
  }

  return (
    <>
      <ul className="worked">
        {workedWith.map((interview, index) => {
          return (
            <li key={index} className="worked__item">
              <button
                type="button"
                className="worked__btn"
                onClick={() => handleClickShowInModal(interview.videoId)}
              >
                <span className="worked__who">{interview.who}</span>
                <span className="visuallyHidden">view more in modal</span>
              </button>
            </li>
          );
        })}
      </ul>

      <ModalMedia
        content={contentId}
        modalStyle="semi-transparent"
        isModalOpen={showDialog}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
}

