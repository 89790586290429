import React from "react";

/**
 * SVG icons that are used multiple times.
 * (Some SVGs are included in content via other means, so not all are here.)
 */
export default function Icons() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: `none` }}
    >
      <symbol id="icon-arrows" viewBox="0 0 24 23" width="100%" height="100%">
        <title>Down arrows</title>
        <desc>Move down to next section</desc>
        <path
          d="M21 11.739l-8.576 8.218a.611.611 0 01-.424.168.61.61 0 01-.424-.168L3 11.739m18-8.625l-8.576 8.218A.611.611 0 0112 11.5a.61.61 0 01-.424-.168L3 3.114"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </symbol>

      <symbol id="icon-play-arrow" viewBox="0 0 22 22">
        <desc>Play video</desc>
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M21.084 11.422L1.759 21.085V1.76z"
        />
      </symbol>

      <symbol id="icon-close-modal-x" viewBox="0 0 26 26">
        <desc>Close modal</desc>
        <g fill="none" fillRule="evenodd">
          <circle cx="13" cy="13" r="13" fill="#000" />
          <path fill="#FFF" d="M5 6.778L6.778 5 21 19.222 19.222 21z" />
          <path fill="#FFF" d="M19.222 5L21 6.778 6.778 21 5 19.222z" />
        </g>
      </symbol>

      <symbol id="icon-instagram" viewBox="0 0 35 35">
        <path
          fill="#460026"
          d="M25.333 2H10.667A8.68 8.68 0 002 10.667v14.666A8.68 8.68 0 0010.667 34h14.666A8.68 8.68 0 0034 25.333V10.667A8.68 8.68 0 0025.333 2zM18 25.333a7.333 7.333 0 110-14.666 7.333 7.333 0 010 14.666zm8.667-14.666a2 2 0 110-4 2 2 0 010 4z"
        />
      </symbol>

      <symbol id="icon-twitter" viewBox="0 0 45 35">
        <path
          fill="#460026"
          d="M39.037 9.599a.712.712 0 00-.283-1.236l-1.12-.285a.707.707 0 01-.467-.994l.623-1.265a.713.713 0 00-.093-.762.706.706 0 00-.729-.233l-2.833.796a.705.705 0 01-.623-.114 7.072 7.072 0 00-4.25-1.42c-3.912 0-7.084 3.18-7.084 7.105v.511a.353.353 0 01-.311.355c-3.981.47-7.792-1.563-11.9-6.31a.723.723 0 00-.723-.212.708.708 0 00-.41.597c-.568 2.338-.339 4.8.651 6.991a.354.354 0 01-.369.512l-1.586-.313a.705.705 0 00-.622.208.716.716 0 00-.186.63 7.322 7.322 0 003.358 5.372.357.357 0 010 .64l-.75.298a.71.71 0 00-.37.981 6.182 6.182 0 004.534 3.524.354.354 0 010 .668 15.471 15.471 0 01-6.077 1.18.72.72 0 00-.85.568.723.723 0 00.566.852 28.351 28.351 0 0011.532 2.743 17.78 17.78 0 009.917-2.842 17.78 17.78 0 007.876-14.822v-1.236a.71.71 0 01.255-.54l2.324-1.947z"
        />
      </symbol>

      <symbol id="icon-youtube" viewBox="0 0 45 45">
        <path
          fill="#460026"
          d="M36.433 8.833H9.567A6.567 6.567 0 003 15.4v15.2a6.567 6.567 0 006.567 6.567h26.866A6.567 6.567 0 0043 30.6V15.4a6.567 6.567 0 00-6.567-6.567zM30.567 23l-11.284 7.267a.834.834 0 01-1.283-.7V15.133a.833.833 0 011.284-.7l11.283 7.217a.834.834 0 010 1.4V23z"
        />
      </symbol>
    </svg>
  );
}
