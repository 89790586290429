import React from "react";
// import SwipeableViews from "react-swipeable-views";
import SwipeableViews from "./SwipeableViewsCustom";
import ViewPast from "./ViewPast";
import ViewPresent from "./ViewPresent";
import ViewFuture from "./ViewFuture";

// const styles = {
//   slide: {
//     // minHeight: 100,
//     color: "#333",
//   },
// };

/**
 * Carousel wrapped around three views
 * (Note: add heroBgIntro={heroBgIntro}) to <ViewPresent /> if using image as hero background instead of video in intro card.)
 */
const Carousel = ({
  slideIndex,
  handleSlideSwipeChange,
  heroBgIntro,
  heroBgIntroMobile,
  heroBgWhat,
  heroBgWhatMobile,
  heroBgHow,
  heroBgHowMobile,
  heroBgInterviews,
  heroBgInterviewsMobile
}) => {
  function handleSwipe(index) {
    handleSlideSwipeChange(index);
  }

  return (
    <>
      <SwipeableViews
        index={slideIndex}
        enableMouseEvents={false}
        onChangeIndex={handleSwipe}
        animateHeight={true}
      >
        <div>
          <ViewPast />
        </div>
        <div>
          <ViewPresent
            heroBgIntro={heroBgIntro}
            heroBgIntroMobile={heroBgIntroMobile}
            heroBgWhat={heroBgWhat}
            heroBgWhatMobile={heroBgWhatMobile}
            heroBgHow={heroBgHow}
            heroBgHowMobile={heroBgHowMobile}
            heroBgInterviews={heroBgInterviews}
            heroBgInterviewsMobile={heroBgInterviewsMobile}
          />
        </div>
        <div>
          <ViewFuture />
        </div>
      </SwipeableViews>
    </>
  );
};

export default Carousel;
