import React from "react";
import { DialogOverlay, DialogContent } from "@reach/dialog";
// import "@reach/dialog/styles.css";

/**
 * Display content in Reach UI Dialog modal component
 * @param {string} content Content from Markdown file for CardWorkedList item
 * @param {string} modalStyle Should be "solid" or "semi-transparent" to dictate visual style
 * @param {Boolean} isModalOpen A true value means modal displays
 * @param {function} handleCloseModal Parent component function that closes modal
 */
export default function Modal({
  content,
  modalStyle,
  isModalOpen,
  handleCloseModal,
  confirmation
}) {
  const handleClose = () => handleCloseModal();

  return (
    <div>
      <DialogOverlay
        isOpen={isModalOpen}
        onDismiss={handleClose}
        className={`modal modal--${modalStyle} ${confirmation ? 'modal--confirmation' : ''}`}
      >
        <DialogContent
          aria-label={`${content && content.frontmatter ? `About ${content.frontmatter.title}` : 'Success Message'}`}
          className={`modal__content--${modalStyle}`}
        >
          {/* <button type="button" className="btn-close" onClick={handleClose}>
            <span className="visuallyHidden">Close</span>{" "}
            <span aria-hidden>
              <svg className="icon icon--close-modal-x">
                <use xlinkHref="#icon-close-modal-x" />
              </svg>
            </span>
          </button> */}

          <div className="inner modal__inner-header">
            <button type="button" className="btn-close" onClick={handleClose}>
              <span className="visuallyHidden">Close</span>
              <span aria-hidden>
                <svg className="icon icon--close-modal-x">
                  <use xlinkHref="#icon-close-modal-x" />
                </svg>
              </span>
            </button>
          </div>

          <div className="modal__inner">
            {
              content && content.frontmatter ?
                <React.Fragment>
                  {content && content.frontmatter && (
                    <h3
                      className="modal__heading"
                      dangerouslySetInnerHTML={{ __html: content.frontmatter.title }}
                    />
                  )}

                  {content && (
                    <div dangerouslySetInnerHTML={{ __html: content.html }} />
                  )}
                </React.Fragment>
                :
                <React.Fragment>
                  <h3 className="modal__heading">Thank you for reaching out</h3>
                  <p>{content}</p>
                </React.Fragment>
            }
          </div>
        </DialogContent>
      </DialogOverlay>
    </div>
  );
}
