import React, { useEffect } from "react";

import Arrows from "./Arrows";

/**
 * Social media feed
 * From Juicer.io
 */
export default function Social() {
  useEffect(() => {
    // add juicer.io JS
    const script = document.createElement("script");
    script.src = "https://assets.juicer.io/embed.js";
    script.async = true;
    document.body.appendChild(script);

    // add juicer.io css
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "https://assets.juicer.io/embed.css";
    document.head.appendChild(link);
  }, []);

  return (
    <div id="view-1-card-7" className="card">
      <div className="inner">
        <h2 className="hdg-2 hdg-2--social ctr">Find me on social:</h2>

        <ul
          className="juicer-feed"
          data-feed-id="nickferrara1"
          data-pages="1"
          data-per="6"
          data-truncate="40"
        ></ul>

        <ul className="social">
          <li className="social__instagram">
            <a href="https://www.instagram.com/nickferrara1/" target="_blank">
              <svg className="icon icon--instagram">
                <use xlinkHref="#icon-instagram" />
              </svg>
            </a>
          </li>
          <li className="social__youtube">
            <a href="https://www.youtube.com/channel/UCnX7tYMPjPhaJBh1lDDg8Dw/" target="_blank">
              <svg className="icon icon--youtube">
                <use xlinkHref="#icon-youtube" />
              </svg>
            </a>
          </li>
          <li className="social__twitter">
            <a href="https://www.twitter.com/rinickulous1" target="_blank">
              <svg className="icon icon--twitter">
                <use xlinkHref="#icon-twitter" />
              </svg>
            </a>
          </li>
        </ul>
      </div>

      <Arrows viewId="1" toCardId="8" color="purp" size="med" />
    </div>
  );
}
