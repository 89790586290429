import React, { useRef, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Arrows from "./Arrows";
import ModalMedia from "./ModalMedia";
import Modal from "./Modal";

/**
 * View: Past
 */
export default function ViewPast() {
  const view0 = useRef(null);

  // Video Modal state
  const [showDialog, setShowDialog] = useState(false);
  const handleCloseModal = () => setShowDialog(false);
  const [contentId, setContentId] = useState();
  // Image Modal State
  const [showModal, setShowModal] = useState(false);
  const handleCloseImgModal = () => setShowModal(false);
  const [imageContentId, setImageContentId] = useState(-1);

  // Content for modals
  const data = useStaticQuery(graphql`
    query ImageModalQuery {
      allMarkdownRemark(
        sort: { fields: frontmatter___slot, order: ASC }
        filter: { frontmatter: { type: { eq: "past" } } }
      ) {
        nodes {
          frontmatter {
            title
            type
            slot
          }
          html
        }
      }
    }
  `);

  const modalContent = data.allMarkdownRemark.nodes;

  // Display content in modal for the button clicked/tapped
  function handleClickShowInModal(id) {
    setContentId(id);
    setShowDialog(true);
  }

  function handleClickShowImgModal(id) {
    setImageContentId(id);
    setShowModal(true);
  }

  return (
    <>
      <section id="view-0" ref={view0} className="view view-0">
        {/* Card 0 */}
        <div className="card card--intro">
          <div className="inner inner--intro-view-0">
            <h2 className="hdg-main hdg-main--mod">
              <span>Here are my</span>{" "}
              <span className="hdg-main__major hdg-main__major--mod">
                Top 5 <br />
                moments
              </span>
            </h2>
          </div>

          <Arrows viewId="0" toCardId="1" color="light" size="lg" />
        </div>

        {/* Card 1: has video button (right) */}
        <div id="view-0-card-1" className="card card--past">
          <div className="inner inner--past past">
            <div className="media">
              <p className="past__year-wrap media__asset">
                <span className="past__year">2019</span>
              </p>

              <h3 className="hdg-past">
                <span className="hdg-past__major">Formula 1</span>
                <span className="hdg-past__minor">Host of F1 Live</span>
              </h3>

              <h4 className="hdg-past__subhead">Special because</h4>
              <p className="past__blurb">
                My emcee / hosting experiences in the world of sports are commonplace throughout my career,  And even though I have had a presence at most of them including
                motorsports this was my first venture into one of the most popular and storied of  International sports, Formula 1.  It was an honor to
                have the opportunity to share the stage with my co host legendary David Coultard and embark on an adrenaline filled and action packed program
                as the "DRIVER" of the show.
              </p>
            </div>

            <button
              type="button"
              className="btn-play btn-play--rt btn-play--formula"
              onClick={() => handleClickShowInModal("vbe6Xumr9i0")}
            >
              <span className="visuallyHidden">play video in modal</span>
              <svg className="icon icon--play-arrow">
                <use xlinkHref="#icon-play-arrow" />
              </svg>
            </button>
          </div>

          <Arrows viewId="0" toCardId="2" color="dark" size="med" />
        </div>

        {/* Card 2: has video button (lt) */}
        <div id="view-0-card-2" className="card card--past">
          <div className="inner inner--past past past--flipped">
            <div className="media media--flipped">
              <p className="past__year-wrap media__asset">
                <span className="past__year">2018</span>
              </p>

              <h3 className="hdg-past">
                <span className="hdg-past__major">ESPYS</span>
                {/* <span className="hdg-past__minor">Host of F1 Live</span> */}
              </h3>

              <h4 className="hdg-past__subhead">Special because</h4>
              <p className="past__blurb">
                The ESPY’s are the awards show that celebrates sports, entertainment and the human spirit. The philanthropic impact as well as the
                impactful stories of sports and the positive influence they impose.  To be the initial voice of the arrival of the best of the best
                as the official announcer is an honor that i wear with pride.
              </p>
            </div>

            <button
              type="button"
              className="btn-play btn-play--lt btn-play--espys"
              onClick={() => handleClickShowInModal("CX5Y9gxQPKQ")}
            >
              <span className="visuallyHidden">play video in modal</span>
              <svg className="icon icon--play-arrow">
                <use xlinkHref="#icon-play-arrow" />
              </svg>
            </button>

            <Arrows viewId="0" toCardId="3" color="dark" size="med" />
          </div>
        </div>

        {/* Card 3: no video button (rt) */}
        <div id="view-0-card-3" className="card card--past">
          <div className="inner inner--past past past">
            <div className="media media">
              <p className="past__year-wrap media__asset">
                <span className="past__year">2015</span>
              </p>

              <h3 className="hdg-past">
                <span className="hdg-past__major">Mens HSBC World Rugby Sevens Series with “The Hoff”</span>
                {/* <span className="hdg-past__minor">Lorem ipsum dolor sit</span> */}
              </h3>

              <h4 className="hdg-past__subhead">This was important</h4>
              <p className="past__blurb">
                As the host of the largest international celbration of Rugby Sevens I knew I was in for an exciting new adventure.  I did not know
                I'd be chatting with David Hasslehoff - As a kid the weekly Knight Rider episode was something I looked forward to with suspense
                and anxiety.  To be able to chat with him in the presence of over 20,000 people was something as that kid couldn't even dreamed of.
                From  Michael Knight to Mitch Buchannon  his international fame is unrivaled as he holds Guinness World Record as the most watched
                man on TV.
              </p>
            </div>

            <button
              type="button"
              className="btn-play btn-play--rt btn-play--rugby"
              onClick={() => handleClickShowInModal("VlrmvneKmN4")}
            >
              <span className="visuallyHidden">play video in modal</span>
              <svg className="icon icon--play-arrow">
                <use xlinkHref="#icon-play-arrow" />
              </svg>
            </button>

            <Arrows viewId="0" toCardId="4" color="dark" size="med" />
          </div>
        </div>

        {/* Card 4: no video button (lt) */}
        <div id="view-0-card-4" className="card card--past">
          <div className="inner inner--past past past--flipped">
            <div className="media media--flipped">
              <p className="past__year-wrap media__asset">
                <span className="past__year">2008</span>
              </p>

              <h3 className="hdg-past">
                <span className="hdg-past__major">MTV RockBand Live Tour</span>
                {/* <span className="hdg-past__minor">
                  Host of NBA All Star Game
                </span> */}
              </h3>

              <h4 className="hdg-past__subhead">This was important</h4>
              <p className="past__blurb">
                A 2 month long North American Arena tour  with several epic rock bands.  Living on a tour bus and traveling across the continent and
                playing a different arena and city each night was transforming and something that I will never forget.  Possessing the ability to engage
                and create a positive reaction from over 20,000 people night in night out is empowering to say the least.  There's a good story on the
                audition that landed me the gig, be sure to ask me sometime.
              </p>
            </div>

            <button
              type="button"
              className="btn-play btn-play--lt btn-play--mtv"
              onClick={() => handleClickShowInModal("cb-7FMxLfnY")}
            >
              <span className="visuallyHidden">play video in modal</span>
              <svg className="icon icon--play-arrow">
                <use xlinkHref="#icon-play-arrow" />
              </svg>
            </button>

            <Arrows viewId="0" toCardId="5" color="dark" size="med" />
          </div>
        </div>

        {/* Card 5: no video button (rt) */}
        <div id="view-0-card-5" className="card card--past">
          <div className="inner inner--past past past">
            <div className="media media">
              <p className="past__year-wrap media__asset">
                <span className="past__year">2015</span>
              </p>

              <h3 className="hdg-past">
                <span className="hdg-past__major">Meeting the GOAT! AKA Jerry Rice</span>
                {/* <span className="hdg-past__minor">Lorem ipsum dolor sit</span> */}
              </h3>

              <h4 className="hdg-past__subhead">Special because</h4>
              <p className="past__blurb">
                This one is bittersweet, to meet the G.O.A.T. and someone who I admired since adolescence was a true gift and reflection to how
                fortunate I am to continuously be in the presence of greatness. Rice is the career leader in most major statistical categories for
                wide receivers, including receptions, touchdown receptions, and receiving yards in addition to being a 13 time Pro Bowl Selection,
                Hall of Famer, 3 x Super Bowl Champion, Super Bowl MVP, and owner of over 100 NFL Records.  As much as it saddens me that the entire
                interview was lost due to camera malfunction.  I  was grateful to find this picture after scouring the internet.  Looking forward to
                the second chance and making sure the footage survives!
              </p>
            </div>

            <button
              type="button"
              className="btn-play btn-play--rt btn-play--jr"
              onClick={() => handleClickShowImgModal(0)}
            >
              <span className="visuallyHidden">play video in modal</span>
              {/* <svg className="icon icon--play-arrow">
                <use xlinkHref="#icon-play-arrow" />
              </svg> */}
            </button>
          </div>
        </div>
      </section>

      <ModalMedia
        content={contentId}
        modalStyle="semi-transparent"
        isModalOpen={showDialog}
        handleCloseModal={handleCloseModal}
      />

      <Modal
        content={imageContentId > -1 ? modalContent[imageContentId] : ""}
        modalStyle="solid"
        isModalOpen={showModal}
        handleCloseModal={handleCloseImgModal}
      />
    </>
  );
}
